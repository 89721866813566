import React from "react"

import useNav from "../../../hooks/useNav"
import { useTranslation } from "../../../i18n/translate"
import Button from "../../button/button"
import Tile from "../../tile/tile"
import "./students-overview.sass"

const StudentsOverview = () => {
  const internshipNav = useNav({ uid: "internship" })
  const workingStudentsNav = useNav({ uid: "working-students" })
  const thesisNav = useNav({ uid: "thesis" })

  const { t } = useTranslation()

  const tiles = [
    {
      title: t(internshipNav.translationKey),
      description: "",
      linkTitle: t(internshipNav.translationKey),
      linkPath: internshipNav.url,
    },
    {
      title: t(workingStudentsNav.translationKey),
      description: "",
      linkTitle: t(workingStudentsNav.translationKey),
      linkPath: workingStudentsNav.url,
    },
    {
      title: t(thesisNav.translationKey),
      description: "",
      linkTitle: t(thesisNav.translationKey),
      linkPath: thesisNav.url,
    },
  ]

  return (
    <div className={"_fp-studies-and-training-overview"}>
      <div className={"_fp-studies-and-training-overview__content"}>
        {tiles.map(({ title, description, linkTitle, linkPath }, i) => (
          <Tile key={`students-overview-${i}`}>
            <h3
              className={
                "_fp-heading-1 _fp-studies-and-training-overview__content__title"
              }
            >
              {title}
            </h3>
            <p
              className={
                "_fp-text _fp-studies-and-training-overview__content__copy"
              }
            >
              {description}
            </p>
            {linkPath && (
              <Button
                buttonSize={"large"}
                bold={true}
                buttonLinkPath={linkPath}
                buttonStyle={"green"}
                buttonLabel={linkTitle}
              />
            )}
          </Tile>
        ))}
      </div>
    </div>
  )
}

export default StudentsOverview
